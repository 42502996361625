
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import VWrapper from '@/components/shared/VWrapper.vue';
import VenuePanicLoader from '@/components/venue/VenuePanicLoader.vue';
import PanicForm from '@/components/venue/form/PanicForm.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';

const venue = namespace('venue');

@Component({
  components: { PanicForm, VenuePanicLoader, VWrapper },
})
export default class VenuePanic extends mixins(StackedForm, Notification) {
  @venue.State('active') public venue!: Venue;
  @venue.Action('panic') public panic!: any;
  @venue.Action('show') public show!: any;

  public $refs!: {
    form: InstanceType<typeof PanicForm> & { getData: () => any };
  };

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public async mounted(): Promise<void> {
    this.$startLoading('venue');
    await this.show({ id: this.$route.params.id });
    this.$stopLoading('venue');
  }

  public async save() {
    this.validate().then(async (res: any) => {
      if (this.isValid(res)) {
        await this.panic({ ...this.$refs.form.getData(), id: this.$route.params.id });
        this.$router.push({ name: 'venue.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
