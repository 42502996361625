
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '../../../mixins/StackedForm';

@Component({
  components: { VFormBuilder },
})
export default class PanicForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [{ name: 'panicEndAt', type: InputType.DateTime, label: 'venue.form.panicEndAt' }];
  }
}
